import React from "react"
import { AppFunctionComponent } from "../../../types"
import styled from "styled-components"
import Section, {
  SectionArticle,
  SectionHeading,
  SectionLayout,
} from "../../../components/common/sections/section.component"
import { PrimaryHeading } from "../../../components/common/heading.component"
import { BasicButtonLink } from "../link/link.component"
import { Path } from "../../../navigation/constants/routes"
import CheckIcon from "../../../images/icons/circle-check-arrow-thin.inline.svg"
import useTranslationHook from "../../../hooks/use-translation.hook"

const ConfirmationSection = styled(Section)`
  --text-max-width: 560px;

  ${SectionHeading} {
    margin-top: 20px;
    margin-bottom: 30px;

    ${PrimaryHeading} {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 30px;

      @media (max-width: ${({ theme }) =>
          theme.breakpoint.tabletHorizontal}px) {
        margin-top: 30px;
      }
    }
  }

  svg {
    width: 50%;
    height: auto;
  }

  ${SectionArticle} {
    text-align: center;
  }
`

const ConfirmationPage: AppFunctionComponent = () => {
  const { translate } = useTranslationHook()
  return (
    <ConfirmationSection layout={SectionLayout.Text}>
      <SectionHeading>
        <CheckIcon />
        <PrimaryHeading>{translate("confirmation-successfuly")}</PrimaryHeading>
      </SectionHeading>
      <SectionArticle>
        <p>{translate("confirmation-thank-you")}</p>
        <BasicButtonLink to={Path.Home}>
          {translate("confirmation-homepage")}
        </BasicButtonLink>
      </SectionArticle>
    </ConfirmationSection>
  )
}

export default ConfirmationPage
