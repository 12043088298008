import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo.component"
import { Menu } from "../../navigation/types/menu.interface"
import { AppFunctionComponent, TextBuilder } from "../../types"
import ConfirmationPage from "../../ui/components/confirmation/confirmation.component"

type ConfirmationProps = {
  data: {
    topNavigationContent: Menu
    footerNavigationContent: Menu
    promobarContent: TextBuilder
  }
}

const ConfirmationPL: AppFunctionComponent<ConfirmationProps> = ({
  data: {
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  },
}) => (
  <Layout
    promobarContent={promobarContent}
    topNavigationItems={topNavigationItems}
    footerNavigationItems={footerNavigationItems}
  >
    <SEO title="Confirmation" />
    <ConfirmationPage />
  </Layout>
)

export const query = graphql`
  query ConfirmationQueryPL($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: "pl-PL" }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: "pl-PL" }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "67Y5XVRqB0r7iJbO4N8zxx" }
      node_locale: { eq: "pl-PL" }
    ) {
      text {
        raw
      }
      node_locale
    }
  }
`

export default ConfirmationPL
